<template>
  <div class="result">
    <van-nav-bar
      :title="title"
      left-text="返回首页"
      left-arrow
      @click-left="onClickLeft"
    />
    <p class="iconfont icon" style="color: #63DED2" v-if="type === 'result'">&#xe610;</p>
    <p style="color: #63DED2" v-if="type === 'result'">支付成功</p>

    <p class="iconfont icon" style="color: #F15867"  v-if="type === 'begin'" @click="reload">&#xe759;</p>
    <p style="color: #F15867" v-if="type === 'begin'">{{ payStatus }}</p>

    <p class="iconfont icon" style="color: #F15867" v-if="type === 'error'">&#xe60e;</p>
    <p v-if="type === 'error'" style="margin-bottom: 60px">未支付</p>

    <!-- <van-button type="danger" class="resBtn TopBtn" v-if="type === 'begin'" @click="again">重新支付</van-button> -->
    <!-- <van-button type="danger" class="resBtn TopBtn" v-if="type === 'result'" @click="express">查看物流</van-button> -->
    <van-button type="default" class="resBtn" @click="onClickLeft">返回首页</van-button>
    <van-overlay :show="bindShow">
      <van-loading size="24px" vertical style="margin-top: 50%">数据查询中</van-loading>
    </van-overlay>
  </div>
</template>

<script>
let interval
import { getOrderStatus } from 'api/api'
export default {
  data () {
    return {
      type: 'begin',
      title: '支付结果',
      orderNo: '',
      bindShow: true,
      payStatus: '支付信息查询中'
    }
  },
  created () {
    this.orderNo = this.$route.params.id
    // this._getOrderStatus()
    console.log('参数', this.$store.state)
    // this.title = `id${this.$store.state.orderId}`
    this.loadStatus()
  },
  methods: {
    reload () {
      location.reload()
    },
    loadStatus () {
      let count = 0
      let _this = this
      interval = setInterval(function(){
        count += 1
        _this._getOrderStatus()
        if(count === 5){    
          _this.bindShow = false
          if (_this.type === 'begin') {
            _this.payStatus = '未查询到支付信息，如果您已支付请点击图标刷新'
          }
          clearInterval(interval)
        }
      }, 1000)
    },
    // 获取订单支付状态
    _getOrderStatus () {
      let params = {
        orderNo: this.orderNo
      }
      getOrderStatus(params).then(res => {
        if (res && res.code === 200) {
          if (res.result) {
            clearInterval(interval)
            this.type = 'result'
            this.$router.push({
              path: `/Details/${this.orderNo}`
            })
          } else if (!res.result) {
            this.type = 'begin'
          } else {
            this.type = 'error'
          }
        }
      })
    },
    onClickLeft () {
      // this.$router.replace({
      //   path: `/placeAnOrder/${this.$store.state.orderId}`
      // })
      this.$router.push({
        path: `/placeAnOrder/${this.$store.state.orderId}`
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .result
    width 100%
    height 100%
    background-color #FAFAFA
    text-align center
    .icon
      font-size 51px
      margin-top 36px
      margin-bottom 16px
    .TopBtn
      margin-top 68px
    .resBtn
      width 90%
      margin-top 50px
      margin-bottom 20px
</style>